@import "../variables/base";
@import "css3";
@import "devices";

@mixin inline-block($min-height: "") {
  display: -moz-inline-stack;
  display: inline-block;

  @if $min-height != "" {
    min-height: $min-height;
  }
}

@mixin contain-floats() {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin site-width-container() {
  max-width: $site-width;
  margin: 0 $gutter-half;

  @include media(tablet) {
    max-width: $site-width;
    margin: 0 $gutter-half;
  }

  // @include media(desktop) {
  //   width: $site-width;
  // }

  @include media(
    $min-width: (
      $site-width + $gutter * 2
    )
  ) {
    margin: 0 auto;
  }
}

@mixin outdent-to-full-width() {
  max-width: 100%;
  width: 100%;
}

@mixin grid-row() {
  @include contain-floats();
  // margin: 0 (-$gutter-one-quarter);
}

@mixin grid-column($width, $full-width: tablet, $float: left) {

  margin: 0 0 $gutter-half 0;

  @include media($full-width) {
    float: $float;
    // width: percentage($width);
    width: calc(#{percentage($width)} - #{$gutter-half});
    margin: 0 $gutter-half $gutter-half 0;
  }

  // padding: 0 $gutter-one-quarter;
  // width: calc(100% - #{$gutter-one-quarter * 2});
  @include box-sizing(border-box);
}


