@import "css3";
@import "devices";

@mixin button($colour: $button-colour) {
  background-color: $colour;
  transition: background-color 0.5s ease;
  position: relative;

  @include inline-block;
  padding: 10px 8px 7px;
  border: none;

  @include border-radius(0);
  outline: 1px solid transparent;
  outline-offset: -1px;
  -webkit-appearance: none;
  @include box-shadow(0 2px 0 darken($colour, 15%));

  font-size: 19px;
  line-height: 1.25;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  cursor: pointer;

  &:visited {
    background-color: $colour;
  }

  &:hover,
  &:focus {
    background-color: darken($colour, 5%);
  }

  &:active {
    top: 2px;
    @include box-shadow(0 0 0 $colour);
  }

  // Disabled button
  &.disabled,
  &[disabled="disabled"],
  &[disabled] {
    @include opacity(0.5);
    &:hover {
      cursor: default;
      background-color: $colour;
    }

    &:active {
      top: 0;
      @include box-shadow(0 2px 0 darken($colour, 15%));
    }
  }

  @if lightness($colour) < 50% {
    color: $white;

    &:link,
    &:link:focus,
    &:hover,
    &:focus,
    &:visited {
      color: $white;
    }
  } @else {
    color: $text-colour;

    &:link,
    &:link:focus,
    &:hover,
    &:focus,
    &:visited {
      color: $text-colour;
    }
  }

  &:before {
    content: "";
    height: 110%;
    width: 100%;
    display: block;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:active:before {
    top: -10%;
    height: 120%;
  }
}
