/* You can add global styles to this file, and also import other style files */
@import 'helpers/variables/palette';
@import 'helpers/mixins/grid';
@import 'core/reset';
@import 'core/grid';
@import 'core/typography';
@import 'core/tables';
@import 'forms/buttons';
@import 'forms/form-elements';
@import 'forms/form-validation';
@import 'core/base';
@import 'core/header';
@import 'core/main';
@import 'core/footer';
@import 'icons/user';
@import 'icons/menu';
@import 'icons/close';
@import 'icons/search';
@import 'icons/alert';
@import 'bootstrap_slider';
@import 'icons/chat';
@import 'icons/favourite';