@use "sass:math";
@import "../helpers/mixins/buttons";

// Buttons

.button {
  @include button($button-colour);
  @include box-sizing(border-box);

  @include media(mobile) {
    width: 100%;
    text-align: center;
    margin-bottom: 0px!important;
  }
}

.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:focus {
  outline: 3px solid $focus-colour;
}

// Disabled buttons
.button[disabled="disabled"] {
  background: $button-colour;
}

.button[disabled="disabled"]:focus {
  outline: none;
}

// Start now buttons
.button-start,
.button-get-started {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  padding: 8px 10px 7px;
}

.button-secondary {
  @include button($grey-3);
  @include core-19;
}
// warning button
.button-warning{
  @include button($red);
  @include core-19;
  margin-right: 10px;
}
// x close button on notification/error container
.button-close {
  position: absolute;
  right: $gutter-half;
  top: $gutter-half;
  width: 24px;
  height: 24px;
  opacity: 0.5;
  background: transparent;
  border: 0;
  transition: opacity 0.5s ease;

  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 10px;
    content: " ";
    height: 25px;
    width: 4px;
    background-color: $black;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

// general style for icon only buttons

.button-icon {
  @include button($grey-4);
  box-shadow: none;
  padding: 0;
  width: 37px;
  height: 35px;
  margin: 0 math.div($gutter-one-third, 2);
  padding: 0;
}

.button-right {
  float: right;
}

// light button

.button-light {
  @include button($grey-2);
  @include box-sizing(border-box);
  @include bold-16;
  box-shadow: none;
  margin-left: $gutter-one-quarter;
  margin-bottom: 0;
  padding: $gutter-one-quarter;

  &.active {
    @include button($focus-colour);
    box-shadow: none;
    margin-left: $gutter-one-quarter;
    margin-bottom: 0;
    padding: $gutter-one-quarter;

    @include media(mobile) {
      margin-left: 0;
    }
  }

  @include media(mobile) {
    margin-left: 0;
  }

}

.button-link {
  @include box-sizing(border-box);
  @include core-19;
  margin-bottom: 0;
  margin-right: 15px!important;

  &.active {
    @include button($focus-colour);
    box-shadow: none;
    margin-bottom: 0;

    @include media(mobile) {
      margin-left: 0;
    }
  }

  @include media(mobile) {
    margin-left: 0;
  }

}

button {
  border: 2px solid transparent !important;
  &:focus {
    outline: 3px solid transparent !important;
    border-color: #fd0 !important;
    box-shadow: inset 0 0 0 1px #fd0 !important;
  }
}